import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Image, NameList } from 'ui/acro'
import TimeCodeBadge from './TimeCodeBadge'
import { CardSubtitle, CardTitle } from '../styles'

// this version of the class card is used on the Most Popular and New Classes HP carousels
const ClassCard = forwardRef(
  (
    {
      carouselItem,
      humanDuration,
      id,
      plan,
      videoThumbnail,
      title,
      planEntryUrl
    },
    ref
  ) => {
    let image
    if (typeof videoThumbnail !== 'undefined') {
      image = videoThumbnail
    }
    return (
      <Flex
        ref={ref}
        id={id}
        flexDirection='column'
        position='relative'
        className={carouselItem ? 'swiper-slide unstyled' : 'unstyled'}
        style={{ minWidth: '198px' }}
        as='a'
        href={planEntryUrl}
      >
        {image && (
          <Box position='relative' mb={['8px', '10px']}>
            <Image
              src={image}
              objectFit='cover'
              position='absolute'
              top='0'
              left='0'
            />
            <TimeCodeBadge
              fontSize='13px !important'
              bottom='16px !important'
              left='16px !important'
            >
              {humanDuration}
            </TimeCodeBadge>
          </Box>
        )}
        {title && (
          <CardTitle id='SeriesTitle' as='div' paddingBottom='6px'>
            {title}
          </CardTitle>
        )}
        {plan.coaches && (
          <CardSubtitle as='div'>
            <NameList people={plan.coaches} />
          </CardSubtitle>
        )}
        <CardSubtitle as='div'>
          {plan.primaryCategory && plan.primaryCategory.title}
          {plan.subcategory && ` / ${plan.subcategory.title}`}
          {plan.difficultyLevel && ` • ${plan.difficultyLevel}`}
        </CardSubtitle>
      </Flex>
    )
  }
)

ClassCard.propTypes = {
  duration: PropTypes.string,
  id: PropTypes.number,
  plan: PropTypes.object,
  title: PropTypes.string.isRequired,
  videoThumbnail: PropTypes.string.isRequired,
  planEntryUrl: PropTypes.string.isRequired
}

export default ClassCard
