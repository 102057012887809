const GEOLOCATION_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://geolocation.alo.software'
    : 'https://geolocation.dev.alo.software'

export const getCountry = async () => {
  try {
    const response = await fetch(GEOLOCATION_URL)
    const country = await response.json()
    const result = country.country_code === 'CA' ? 'CA' : 'US'

    return result
  } catch (error) {
    console.error(error)
    return 'US'
  }
}

export default getCountry
