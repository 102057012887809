import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Image, NameList } from 'ui/acro'
import ClassCountBadge from './ClassCountBadge'
import { CardSubtitle, CardTitle } from '../styles'

const ProductCard = forwardRef(
  (
    {
      carouselItem,
      id,
      classCount,
      instructors,
      primaryCategory,
      primarySubcategory,
      thumbnails,
      title,
      url,
      introVideoThumbnail,
      thumbnailImage,
      coaches,
      alignBox,
      variantTitle,
      variantSubtitle,
      coverPhoto
    },
    ref
  ) => {
    const defaultImg =
      'https://alomoves.s3.amazonaws.com/manual_uploads/shared/search/placeholder-cards/rectangle-card.jpg'

    const image =
      coverPhoto?.url ||
      (thumbnails && (thumbnails.medium || thumbnails.default)) ||
      (introVideoThumbnail && introVideoThumbnail) ||
      (thumbnailImage === null ? defaultImg : thumbnailImage)

    return (
      <Flex
        ref={ref}
        flexDirection='column'
        alignItems={alignBox}
        className={carouselItem ? 'swiper-slide unstyled' : 'unstyled'}
        as='a'
        href={url}
      >
        {image && (
          <Box position='relative' mb={['8px', '10px']}>
            <Image
              src={image}
              objectFit='cover'
              position='absolute'
              top='0'
              left='0'
            />
            {classCount > 0 && (
              <ClassCountBadge
                bottom='16px !important'
                left='16px !important'
              >{`${classCount} Class${
                classCount <= 1 ? '' : 'es'
              }`}</ClassCountBadge>
            )}
          </Box>
        )}
        {title && (
          <CardTitle
            id='SeriesTitle'
            as='div'
            paddingBottom='6px'
            variant={variantTitle}
          >
            {title}
          </CardTitle>
        )}
        {instructors && (
          <CardSubtitle as='div'>
            <NameList people={instructors || coaches} />
          </CardSubtitle>
        )}
        {coaches && (
          <CardSubtitle as='div' variant={variantSubtitle}>
            <NameList people={coaches} />
          </CardSubtitle>
        )}

        <CardSubtitle as='div'>
          {primaryCategory && primaryCategory.name}
          {primarySubcategory?.name && ` / ${primarySubcategory.name}`}
        </CardSubtitle>
      </Flex>
    )
  }
)

ProductCard.propTypes = {
  duration: PropTypes.string,
  instructors: PropTypes.array,
  id: PropTypes.string,
  primaryCategory: PropTypes.object,
  primarySubcategory: PropTypes.object,
  title: PropTypes.string.isRequired,
  thumbnails: PropTypes.object,
  url: PropTypes.string.isRequired,
  coverPhoto: PropTypes.array,
  coaches: PropTypes.array,
  alignBox: PropTypes.string,
  variantSubtitle: PropTypes.string,
  variantTitle: PropTypes.string
}

export default ProductCard
