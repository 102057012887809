import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { StopwatchSvg } from 'images/latest'

const StopwatchIcon = ({
  color = 'black',
  width = '30px',
  height = '30px',
  strokeWidth = '2'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    fill={color}
    SVG={StopwatchSvg}
    svgProps={{ stroke: color, strokeWidth: strokeWidth }}
  />
)

export default StopwatchIcon
