import styled from 'styled-components'
import { css } from '@styled-system/css'
import Fade from 'ui/chandra/animations/Fade'

const FadeBox = styled(Fade)(() =>
  css({
    // accommodate for navbar when using section ID as a jump/anchor link
    pt: ['3.5em', '3.5em', '5.9em'],
    mt: ['-3.5em', '-3.5em', '-5.9em']
  })
)
export default FadeBox
