import { useEffect, useState } from 'react'
import { getCountry } from 'helpers/getCountry'

const useGetCountry = () => {
  const [country, setCountry] = useState('')

  useEffect(() => {
    if (!country) {
      getCountry().then((res) => {
        setCountry(res)
      })
    }
  }, [])

  return country
}

export default useGetCountry
